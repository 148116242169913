@media only screen and (max-width: 767px) {
  .primary:after {
    content: "";
    display: block;
    position: absolute;
    z-index: 2;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #ccc;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }

  .cell:nth-of-type(n + 2) {
    display: none;
  }

  input[type="radio"],
  input[type="checkbox"] {
    display: block;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 4rem;
    opacity: 0;
  }

  input[type="radio"]:checked,
  input[type="checkbox"]:checked {
    z-index: -1;
  }

  input[type="radio"]:checked ~ .cell,
  input[type="checkbox"]:checked ~ .cell {
    display: block;
  }

  input[type="radio"]:checked ~ .cell:nth-of-type(n + 2):before,
  input[type="checkbox"]:checked ~ .cell:nth-of-type(n + 2):before {
    content: attr(data-label);
    display: inline-block;
    width: 60px;
    background: #999;
    border-radius: 10px;
    height: 20px;
    margin-right: 10px;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
  }

  input[type="radio"]:checked ~ .primary:after,
  input[type="checkbox"]:checked ~ .primary:after {
    position: absolute;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    border-top: 10px solid #ccc;
    z-index: 2;
    right: 1rem;
    top: 50%;
    transform: translateY(-25%);
  }
}
