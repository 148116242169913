@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent !important;
  @apply focus-within:focus:focus-visible:outline-none;
}

body {
  @apply m-0 p-0 font-Montserrat text-white text-base font-normal;
}

*::-webkit-scrollbar {
  width: 8px;
  background-color: #011019;
}

*::-webkit-scrollbar-thumb {
  background-color: #40a5cacb;
  border-radius: 8px;
}

.range-slider {
  @apply w-full;
}

.range-slider [data-reach-slider-track] {
  @apply h-0.5 w-full bg-white bg-opacity-10;
}

.range-slider [data-reach-slider-range] {
  @apply h-0.5;
  background: linear-gradient(90deg, #0ce5d5 0%, #149fd9 100%);
}

.range-slider [data-reach-slider-handle] {
  @apply h-4 w-4 cursor-pointer rounded-full -top-1.5;
  background: linear-gradient(90deg, #0ce5d5 0%, #149fd9 100%);
}

.leaderboard-1 {
  background: linear-gradient(90deg, #e6374b, #ff7f7f);
}

.leaderboard-2 {
  background: linear-gradient(90deg, #6035bb 6.73%, #9ea9e1 92.31%);
}

.leaderboard-3 {
  background: linear-gradient(87.41deg, #fc8619 52.56%, #fda371 106.98%);
}

.comp-anchor {
  transition: 500ms;
  right: -170px;
}

.comp-anchor:hover {
  right: -16px;
}

.ringing-cup {
  animation-name: shake;
  animation-timing-function: ease-in-out;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
}

@keyframes shake {
  0%,
  100% {
    transform: rotate(0deg);
  }
  20%,
  60% {
    transform: rotate(10deg);
  }
  40%,
  80% {
    transform: rotate(-10deg);
  }
}

.gradient-box:before,
.gradient-box > div:before {
  position: absolute;
  -webkit-transition: all 0.8s ease;
  transition: all 0.8s ease;
  display: inline-block;
  width: 100%;
  height: 100%;
  content: "";
  z-index: -1;
  left: -4px;
  top: -4px;
  opacity: 0.4;
  -webkit-filter: blur(4px);
  filter: blur(4px);
  border-radius: 12px;
  width: calc(100% + 8px);
  height: calc(100% + 8px);
  background: radial-gradient(19.49% 58.52% at 50% 0, rgba(249, 196, 60, 0.3) 0, rgba(231, 166, 1, 0) 100%),
    radial-gradient(50% 50% at 100% 100%, #520aeb 10.27%, rgba(159, 115, 255, 0) 100%),
    radial-gradient(77.86% 77.86% at 100% 0, #00a7ff 0, rgba(0, 166, 254, 0.12) 100%),
    radial-gradient(50% 50% at 0 0, #ff8d6c 0, rgba(255, 92, 0, 0.6) 100%),
    radial-gradient(70.24% 70.24% at 0 100%, #e81d72 0, rgba(255, 0, 0, 0) 100%);
  background-blend-mode: overlay, normal, normal, color-dodge, normal;
  mix-blend-mode: normal;
}

.text-gradient {
  background: linear-gradient(200deg, rgb(50, 197, 255) 0%, rgb(182, 32, 224) 45.93%, rgb(255, 170, 18) 86.01%);
}

#wormhole-connect {
  margin: -52px -16px 0; 
  z-index: 10;
}

#wormhole-connect > div > div {
  max-width: unset !important;
} 