.swiper-wrapper {
  align-items: center;
  display: flex;
}

.swiper-slide {
  width: 320px;
}

#wisp-drawer-checkbox:checked ~ .prediction-history-overlay {
  display: block;
}

#wisp-drawer-checkbox:checked ~ .prediction-history {
  transform: translateX(0%);
}
